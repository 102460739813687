<template>
  <!-- 服务优势 -->
  <div>
    <PlateTile
      title="服务优势"
      describe="创新、诚信、高效、协作，满足顾客的一切需求是中天云网永远的追求"
    />

    <div class="one-stop-box">
      <div class="one-stop">
        <span class="one-stop-one">一站式</span>
        <span class="one-stop-one">顶层设计服务</span>
        <span class="one-stop-bottom">全生态新型AI智慧城市整体解决方案</span>
      </div>

      <div class="process-box">
        <div class="process" v-for="(item, index) in processList" :key="item">
          <span class="process-name">{{ item }}</span>
          <img
            class="process-img"
            v-imgLazy="yellowRightArrow"
            v-if="index !== processList.length - 1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlateTile from './PlateTitle.vue'

export default {
  name: 'ServiceAdvantage',
  components: {
    PlateTile
  },

  data() {
    return {
      processList: [
        '总体规划',
        '顶层设计',
        '项目建议书',
        '可行性研究方案',
        '初步设计方法'
      ],
      yellowRightArrow: require('@/assets/images/yellow-right-arrow.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.one-stop-box {
  display: flex;
  justify-content: center;

  .one-stop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 336px;
    height: 370px;
    margin-right: 100px;
    background: url('../../../assets/images/one-stop.png') 100% 100% no-repeat;

    .one-stop-one {
      font-size: 50px;
      font-weight: bold;
      color: #08467c;
      text-align: center;
    }

    .one-stop-bottom {
      position: relative;
      color: #777;
      margin-top: 19px;
      padding: 13px 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 67px;
        height: 6px;
        background-color: #08467c;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .process-box {
    display: flex;
    align-items: center;

    .process {
      display: flex;
    }

    .process-name {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 250px;
      border-radius: 35px;
      background-color: #4a89ff;
      color: #fff;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      writing-mode: vertical-lr;
      word-spacing: 20px;
    }

    .process-img {
      width: 35px;
      height: 29px;
      margin: 110px 15px 0;
    }
  }
}
</style>
