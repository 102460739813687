<template>
  <!-- 首页 新闻资讯 -->
  <div class="news">
    <PlateTile
      title="新闻资讯"
      describe="实时更新公司最新动态资讯"
      mt="0px"
      mb="40px"
    />

    <ul class="news-box">
      <li
        class="news-item"
        :class="{ mr: index % 2 === 0 }"
        v-for="(item, index) in infoList"
        :key="item.title"
        @click="toDetail(index)"
      >
        <img v-imgLazy="item.img" style="width: 180px; height: 105px" />
        <span class="news-detail">
          <span class="title"> {{ item.title }}</span>
          <span class="date">{{ item.date }}</span>
        </span>
      </li>
    </ul>

    <button class="more-btn" @click="toNews">查看更多>></button>
  </div>
</template>

<script>
import dataInfo from '@/utils/newsInfo'
import PlateTile from './PlateTitle.vue'

export default {
  name: 'NewsInfo',
  components: {
    PlateTile
  },

  data() {
    return {
      infoList: [
        {
          img: require('@/assets/images/news-1.png'),
          title: '2023年智慧城市综合服务项目市场分析',
          date: '2023.06.02'
        },
        {
          img: require('@/assets/images/news-2.png'),
          title: '智慧城市未来发展的六个关键启示',
          date: '2023.05.27'
        },
        {
          img: require('@/assets/images/news-3.png'),
          title: '大数据+可视化，智慧党建可以这样做！',
          date: '2023.05.25'
        },
        {
          img: require('@/assets/images/news-4.png'),
          title: '习近平心中的“数字中国”',
          date: '2023.05.24'
        },
        {
          img: require('@/assets/images/news-5.png'),
          title: '智慧城市新趋势：全面融合时代来临！',
          date: '2023.05.17'
        },
        {
          img: require('@/assets/images/news-6.png'),
          title: '再传中标喜讯，中天云网成功拿下成安智慧 党建项目！',
          date: '2023.05.15'
        }
      ]
    }
  },

  mounted() {
    this.infoList = dataInfo.slice(0, 6)
    console.log(this.infoList)
  },

  methods: {
    // 跳转到 新闻详情
    toDetail(item) {
      sessionStorage.setItem('newsIndex', item)
      // this.$EventBus.$emit('setActive', 'News')
      this.$router.push({ name: 'Newsdetail' })
    },

    // 跳转到 新闻资讯
    toNews() {
      // this.$EventBus.$emit('setActive', 'News')
      this.$router.push({ name: 'News' })
    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fcfcfc;
}

.news-box {
  display: flex;
  flex-wrap: wrap;
  width: 1145px;
  margin: 0 auto;
}

.news-item {
  display: flex;
  width: 530px;
  margin-bottom: 27px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 3px 3px 8px #e2e2e2;
  }

  .news-detail {
    flex: 1;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 0 11px 12px;
    box-sizing: border-box;

    .title {
      color: #333;
      font-size: 20px;
      line-height: 29px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /*要显示的行数*/
      -webkit-box-orient: vertical;
    }

    .date {
      font-size: 14px;
      color: #999;
    }
  }
}

.mr {
  margin-right: 85px;
}

.more-btn {
  width: 160px;
  height: 40px;
  border: none;
  background-color: #08467c;
  color: #fff;
  font-size: 14px;
  margin: 45px 50% 0px;
  transform: translateX(-50%);
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    color: #08467c;
    background-color: #fff;
    border: 1px solid #08467c;
  }
}
</style>
