<template>
  <!-- 首页 轮播图 -->
  <section class="banner">
    <el-carousel trigger="click" height="660px" arrow="never" :interval="5000">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img class="box-img" :src="item" />
      </el-carousel-item>
    </el-carousel>
  </section>
</template>

<script>
export default {
  name: 'banner',
  data() {
    return {
      bannerList: [
        require('@/assets/images/banner-1.png'),
        require('@/assets/images/banner-2.png'),
        require('@/assets/images/banner-3.png'),
        require('@/assets/images/banner-4.png')
      ]
    }
  },

  methods: {
    changeImg(index) {
      console.log(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
}

.box-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel ::v-deep .el-carousel__button {
  width: 25px;
  height: 12px;
}

.el-carousel ::v-deep .el-carousel__indicator--horizontal {
  padding: 20px 10px;
}
</style>
