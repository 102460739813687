<template>
  <!-- 标题 -->
  <div class="plate-title" :style="{ 'margin-top': mt, 'margin-bottom': mb }">
    <h2 class="title">{{ title }}</h2>
    <span class="describe">{{ describe }}</span>
  </div>
</template>

<script>
export default {
  name: 'PlateTitle',
  props: {
    title: String,
    describe: String,
    mt: {
      type: String,
      default: '90px'
    },
    mb: {
      type: String,
      default: '49px'
    }
  }
}
</script>

<style lang="scss" scoped>
.plate-title {
  text-align: center;

  .title {
    font-size: 36px;
    color: #08467c;
    font-weight: bold;
    margin: 0 0 19px;
  }

  .describe {
    color: #777;
  }
}
</style>
