<template>
  <!-- AI智慧城市建设框架 -->
  <div class="ai">
    <div class="one-stop">
      <span class="one-stop-one">一站式</span>
      <span class="one-stop-one">顶层设计服务</span>
      <span class="one-stop-bottom">全生态新型AI智慧城市整体解决方案</span>
    </div>

    <img class="ai-img" alt="智慧城市" v-imgLazy="aiSmartCity" />
  </div>
</template>

<script>
export default {
  name: 'AISmart',
  data() {
    return {
      aiSmartCity: require('@/assets/images/ai-smart-city.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.ai {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  .one-stop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 336px;
    height: 370px;
    margin-right: 46px;
    background: url('../../../assets/images/one-stop.png') 100% 100% no-repeat;

    .one-stop-one {
      font-size: 50px;
      font-weight: bold;
      color: #08467c;
      text-align: center;
    }

    .one-stop-bottom {
      position: relative;
      color: #777;
      margin-top: 19px;
      padding: 13px 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 67px;
        height: 6px;
        background-color: #08467c;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .ai-img {
    width: 803px;
    height: 505px;
  }
}
</style>
