<template>
  <!-- 一体化服务模式 -->
  <div class="integrated">
    <div class="process">
      <div class="process-item">
        <div class="process-name">战略规划</div>
        <img class="process-plus" alt="加号" v-imgLazy="whitePlus" />

        <div class="process-name">顶层设计</div>
        <img class="process-plus" alt="加号" v-imgLazy="whitePlus" />

        <div class="process-name">评估咨询</div>
      </div>

      <div class="process-item" style="margin-top: 65px">
        <div class="process-name">建设运营一体化</div>
        <img class="process-plus" alt="加号" v-imgLazy="whitePlus" />

        <div class="process-name">数字城市生态运营</div>
        <img class="process-plus" alt="加号" v-imgLazy="whitePlus" />

        <div class="process-name">资源整合</div>
      </div>
    </div>

    <div class="integrated-mode">
      <span class="one-stop-one">一体化</span>
      <span class="one-stop-one">服务模式</span>
      <span class="one-stop-bottom"
        >“咨询规划—实施集成—运营维护”一体化的服务模式</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Integrated',
  data() {
    return {
      whitePlus: require('@/assets/images/white-plus.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.integrated {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 488.5px;
  width: 100%;
  z-index: 3;

  &::before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/integrated-bg.png') no-repeat;
    background-size: 100% 100%;
  }

  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 108, 255, 0.3);
  }
}

.process {
  margin-right: 105px;

  .process-item {
    display: flex;
    align-items: center;
  }

  .process-name {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 120px;
    width: 120px;
    background-color: #08467c;
    color: #fff;
    font-size: 24px;
    font-weight: bolder;
    border-radius: 50%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 140px;
      height: 140px;
      border: 4px solid #fff;
      border-radius: 50%;
      top: -14px;
      left: -13px;
    }
  }

  .process-plus {
    width: 41px;
    height: 40px;
    margin: 0 32px;
  }
}

.integrated-mode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 370px;
  background: url('../../../assets/images/one-stop.png');

  .one-stop-one {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }

  .one-stop-bottom {
    position: relative;
    color: #fff;
    margin-top: 19px;
    padding: 13px 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 67px;
      height: 6px;
      background-color: #fff;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
