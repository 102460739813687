<template>
  <!-- 服务领域 -->
  <div>
    <PlateTile
      title="服务领域"
      describe="快速规范并高质量地打造各种智慧城市信息化系统"
    />

    <div class="service">
      <div
        class="service-item"
        :class="
          ['smart-city-bg', 'city-internet-bg', 'more-products-bg'][index]
        "
        v-for="(item, index) in serviceList"
        :key="item.index"
        @click="toCenter(item.index)"
      >
        <img class="service-img zindex" :alt="item.name" v-imgLazy="item.png" />
        <span class="service-name zindex">{{ item.name }}</span>
        <img class="service-arrow zindex" alt="箭头" v-imgLazy="rightArrow" />
      </div>
    </div>
  </div>
</template>

<script>
import PlateTile from './PlateTitle.vue'

export default {
  name: 'ServiceArea',
  components: {
    PlateTile
  },

  data() {
    return {
      serviceList: [
        {
          name: 'AI智慧城市',
          png: require('@/assets/images/smart-city.png'),
          index: '1'
        },
        {
          name: '城市互联网',
          png: require('@/assets/images/city-internet.png'),
          index: '2'
        },
        {
          name: '更多产品',
          png: require('@/assets/images/more-products.png'),
          index: '3'
        }
      ],

      rightArrow: require('@/assets/images/right-arrow.png')
    }
  },

  methods: {
    // 跳转到产品中心
    toCenter(index) {
      // this.$EventBus.$emit('setActive', 'Center')
      this.$router.push({
        name: 'Center',
        params: {
          index
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.service {
  display: flex;
  justify-content: center;
}

.service-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  width: 380px;
  height: 390px;
  transition: all 0.3s;
  cursor: pointer;

  .service-img {
    bottom: -25px;
    margin-bottom: 26px;
    transition: all 0.3s;
  }

  .service-name {
    bottom: -25px;
    font-size: 30px;
    color: #fff;
    transition: all 0.3s;
  }

  .service-arrow {
    width: 49px;
    height: 49px;
    bottom: -25px;
    opacity: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .zindex {
    position: relative;
    z-index: 3;
  }

  &:hover {
    .service-img,
    .service-name {
      bottom: 30px;
    }

    .service-arrow {
      opacity: 1;
      bottom: -40px;
    }
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(29, 108, 255, 0.3);
    z-index: 2;
  }
}

.smart-city-bg {
  background-image: url('../../../assets/images/smart-city-bg.png');
}
.city-internet-bg {
  background-image: url('../../../assets/images/city-internet-bg.png');
}
.more-products-bg {
  background-image: url('../../../assets/images/more-products-bg.png');
}
</style>
