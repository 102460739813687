<template>
  <div class="home" id="demo">
    <!-- 轮播图 -->
    <Banner />

    <!-- 服务领域 -->
    <ServiceArea />

    <!-- 服务优势 -->
    <ServiceAdvantage />

    <!-- 一体化服务模式 -->
    <Integrated />

    <!-- AI智慧城市建设框架 -->
    <AISmart />

    <!-- 新闻资讯 -->
    <NewInfo />

    <!-- 版权信息区 -->
    <Copyright />
  </div>
</template>

<script>
import Banner from './conponents/Banner.vue'
import ServiceArea from './conponents/ServiceArea.vue'
import ServiceAdvantage from './conponents/ServiceAdvantage.vue'
import Integrated from './conponents/Integrated.vue'
import AISmart from './conponents/AISmart.vue'
import NewInfo from './conponents/NewsInfo.vue'
import Copyright from '@/components/Copyright'

export default {
  name: 'Home',
  components: {
    Banner,
    ServiceArea,
    ServiceAdvantage,
    Integrated,
    AISmart,
    NewInfo,
    Copyright
  }
}
</script>
